import React, { useContext, useState } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"
import * as Api from "../Api"
import { AuthContext } from "../providers/AuthProvider"
import { waitTillVerified } from "../utils"


export default function SignUpForm({ back, title, intent }) {
  const { refreshUser } = useContext(AuthContext)
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const validateEmail = (email) => {
    return email.length > 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setMessage(null)

    const formData = new FormData(e.target)
    const email = formData.get("email")
    const password = formData.get("password")
    const confirmPassword = formData.get("confirm_password")

    if (!validateEmail(email)) return setMessage("Invalid email")
    if (password.length < 6) return setMessage("Password too short (min 6 characters")
    if (password !== confirmPassword) return setMessage("Passwords do not match")

    setLoading(true)

    try {
      const res = await Api.register(email, password)
      if (res.success) {
        await refreshUser()
        navigate("/account")
      }
    }
    catch (err) {
      setMessage(err.message)
    }
    finally {
      setLoading(false)
    }
  }

  const spinner = (
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )

  return (
    <div className="rounded-2 bg-body-tertiary border px-4 pt-4 pb-3 w-100 tw-max-w-sm">
      <form className="d-flex flex-column w-100" onSubmit={handleSubmit}>
        <div className="d-flex flex-row mb-3">
          {back && <Link onClick={() => window.history.back()}>
            <i className="btn bi bi-arrow-left-circle-fill fs-5 px-1"></i>
          </Link>}
          <h1 className="ms-2 font-bold h2">{title || "Sign Up"}</h1>
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="">
            <div className="form-floating">
              <input name="email" type="email" className="form-control" placeholder="name@example.com" />
              <label htmlFor="floatingInput">Email</label>
            </div>
          </div>
          <div className="">
            <div className="form-floating">
              <input name="password" type="password" className="form-control" placeholder="Password" />
              <label htmlFor="floatingPassword">Password</label>
            </div>
          </div>
          <div className="mb-1">
            <div className="form-floating">
              <input name="confirm_password" type="password" className="form-control" placeholder="Password" />
              <label htmlFor="floatingPassword">Confirm Password</label>
            </div>
          </div>
        </div>
        <div className="tw-min-h-6">
          <p className=" tw-text-red-400 mt-0 mb-0 tw-text-wrap tw-max-w-80">{message}</p>
        </div>
        <div className="d-flex flex-row justify-content-center mb-2">
          <button className="btn border w-100">
            {/* <p className="text-text text-sm">Sign in</p> */}

            {loading ? spinner : <i className="bi bi-arrow-right"></i>}
          </button>
        </div>
        <span className="mt-1 text-center text-secondary">Already have an account? <span><Link to="/login">Login!</Link></span></span>
        {/* <span className="mt-1 text-center text-secondary">Forgot password? <span><Link to="/login">Reset</Link></span></span> */}
      </form>
      {/* <div className="tw-min-w-xl">
        <h2 className="h3">With an account:</h2>
        <ul className="text-body-secondary fs-5 list-unstyled">
          <li><span>Ask unlimited questions</span></li>
          <li><span>Get notified</span></li>
          <li><span>Save bookmarks across devices</span></li>
        </ul>
      </div> */}
    </div>
  )
}

