import React, { useState } from "react";
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { listCategories, listPolicies } from "../Api";
import Section from "../components/Section";
import PolicyCardList from "../components/PolicyCardList";
import { capitalizeAllWords, capitalizeFirstLetter } from "../utils";
import SmallHeading from "../components/SmallHeading";
import SearchBar from "../components/SearchBar";
import { Helmet } from "react-helmet"
import PageTitle from "../components/seo/PageTitle";

export async function loader({ }) {
    const res = await listCategories()
    return res;
}

export default function CategoriesPage() {
    const { categories } = useLoaderData();
    const [allPolicies, setAllPolicies] = useState(null)

    const [sortBy, _setSortBy] = useState("category")

    const setSortBy = (_sortBy) => {
        _setSortBy(_sortBy)
        if(!allPolicies) {
            listPolicies().then(res => setAllPolicies(res.policies))
        }
    }

    const SortByButton = ({ sortByKey }) => {
        const active = sortBy === sortByKey
        const activeClass = active ? "bg-body-secondary" : ""
        return <button onClick={() => setSortBy(sortByKey)} className={`btn rounded-4 HoverButton ${activeClass}`}>{capitalizeAllWords(sortByKey)}</button>
    }

    const CategoryEntry = ({ category }) => {
        const to = "/policies/" + category.name
        return (
            <div>
                <div className="d-flex flex-row align-items-center gap-2">
                    <h3 className="h3">{capitalizeAllWords(category.name)}</h3>
                    <Link className="text-body-secondary" to={to}>{category.policy_count} policies</Link>
                </div>
                <PolicyCardList policies={category.policies_truncated.slice(0, 3)} moreText={(category.policy_count - 3) + " more"} moreTo={to} />
            </div>
        )
    }

    return (
        <div>
            <SmallHeading title={"All Policies"} helmet={true}>
                <SearchBar placeholder={"Search Policies"} />
            </SmallHeading>
            <Section className="bg-body-tertiary" float={sortBy === "category"}>
                <div className="d-flex flex-row justify-content-center gap-2">
                    {
                        ["category", "ranking"].map(x => <SortByButton key={x} sortByKey={x} />)
                    }
                </div>
                {
                    sortBy === "category" ?
                        <div className="d-flex flex-column gap-4">
                            {categories && categories.map(category => <CategoryEntry key={category.name} category={category} />)}
                        </div>
                        :
                        <div>
                            <h3 className="h3">All Policies</h3>
                            {allPolicies ? <PolicyCardList policies={allPolicies} /> : <div></div>}
                        </div>
                }

            </Section>
        </div>
    )
}
