import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer className="p-3 bg-body-tertiary" style={{ paddingTop: "300px !important" }}>
            <div className="m-auto d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top mb-0 tw-max-w-6xl">
                <div className="col-md-4 d-flex align-items-center">
                    <Link to="/" className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
                        {/* <img style={{ width: "50px" }} src={logo_small}></img> */}
                        Privacy Police
                    </Link>
                    <span className="mb-3 mb-md-0 text-body-secondary">&copy; 2024 <a href="https://x.com/JigsawClient">Robin</a> & <a href="https://x.com/omxtamer">Filip</a></span>
                </div>
                <div className="d-flex text-center flex-column flex-sm-row gap-2">
                </div>
                <div className="nav col-md-4 justify-content-end list-unstyled d-flex gap-2">
                    {/* <li className="ms-3"><span className="text-body-secondary">Made with <small><i className="bi bi-heart-fill text-danger"></i></small> in 🇸🇪</span></li> */}
                    <li className="text-body-secondary"><Link to={"/legal/privacy-policy"}>Privacy</Link></li>
                    <li className="text-body-secondary"><Link to={"/legal/terms-of-service"}>Terms</Link></li>
                </div>
                {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex fs-3">
                    <li className="ms-3"><a className="text-body-secondary" href="https://github.com/robinkarlberg/transfer.zip-web/"><i className="bi bi-github"></i></a></li>
                    <li className="ms-3"><a className="text-body-secondary" href="https://twitter.com/transfer_zip"><i className="bi bi-twitter"></i></a></li>
                    <li className="ms-3"><a className="text-body-secondary" href="https://instagram.com/transfer.zip"><i className="bi bi-instagram"></i></a></li>
                    <li className="ms-3"><a className="text-body-secondary" href="https://tiktok.com/@transfer.zip"><i className="bi bi-tiktok"></i></a></li>
                </ul> */}
            </div>
        </footer>
    )
}