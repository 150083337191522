import { useContext, useEffect } from "react"
import { Modal } from 'react-bootstrap'
import { ApplicationContext } from '../providers/ApplicationProvider'
import SignUpForm from "../components/SignUpForm"
import { waitTillVerified } from "../utils"
import { AuthContext } from "../providers/AuthProvider"

export default function WaitForVerificationModal({ show }) {
    const { user } = useContext(AuthContext)
    const { setShowWaitForVerificationModal } = useContext(ApplicationContext)

    const onCancel = () => {
        // setShowWaitForVerificationModal(false)
    }

    const onConfirm = () => {

    }

    const spinnerlg = (
        <div className="text-body-tertiary spinner-border spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )

    useEffect(() => {
        if (user && !user.verified) {
            setShowWaitForVerificationModal(true)
            waitTillVerified(2000).then(() => {
                setShowWaitForVerificationModal(false)
            }).catch(err => {
                console.log("[WaitForVerificationModal]", err)
            })
        }
    }, [user])

    return (
        <>
            <Modal backdrop="static" dialogClassName="tw-max-w-sm" show={show} centered onHide={onCancel}>
                <div className="rounded-2 bg-body-tertiary border px-4 pt-4 pb-3 w-100 tw-max-w-sm">
                    <div className="d-flex flex-row gap-3 mb-3">
                        <h1 className="font-bold h2">Verify Account</h1>
                        {spinnerlg}
                    </div>
                    <p className="fs-5">
                        We have sent a verification link to your email. Check your spam folder.
                    </p>
                    <p className="fs-6 text-body-secondary">
                        Keep this page open, you will be redirected when verified.
                    </p>
                </div>
            </Modal>
        </>

    )
}