import React, { useContext, useEffect, useState } from "react";
import LoginForm from "../components/LoginForm";
import { Link } from "react-router-dom";
import SignUpForm from "../components/SignUpForm";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { Helmet } from "react-helmet";
import PageTitle from "../components/seo/PageTitle";

export default function SignUpPage() {

  return (
    <div className="d-flex flex-row justify-content-center w-100 px-3">
        <PageTitle sub={`Sign Up`} />
      <div className="flex-grow-1 d-flex flex-row justify-content-center align-items-center tw-min-h-[80vh]">
        <SignUpForm back={true} />
      </div>
    </div>

  )
}

