import { createContext, useCallback, useContext, useEffect, useState } from "react"
import * as Api from "../Api"
import { Outlet } from "react-router-dom"
import { AuthContext } from "./AuthProvider"
import SignUpModal from "../modals/SignUpModal"
import SharePolicyModal from "../modals/SharePolicyModal"
import SubscribeModal from "../modals/SubscribeModal"
import { handleShareLogic } from "../utils"
import WaitForVerificationModal from "../modals/WaitForVerificationModal"

export const ApplicationContext = createContext({})

export const ApplicationProvider = ({ }) => {
    const [signUpModalTitle, setSignUpModalTitle] = useState(null);
    const [subscribeModalTitle, setSubscribeModalTitle] = useState(null);

    const [showSignUpModal, _setShowSignUpModal] = useState(false);
    const setShowSignUpModal = (show, title) => {
        _setShowSignUpModal(show);
        if (show) setSignUpModalTitle(title || null);
    };

    const [showSubscribeModal, _setShowSubscribeModal] = useState(false);
    const setShowSubscribeModal = (show, title) => {
        _setShowSubscribeModal(show);
        if (show) setSubscribeModalTitle(title || null);
    };

    const [showShareModal, setShowShareModal] = useState(false);
    const [shareModalPolicy, setShareModalPolicy] = useState(null);
    const [showWaitForVerificationModal, setShowWaitForVerificationModal] = useState(false);

    const sharePolicy = (policy) => {
        handleShareLogic(policy, setShareModalPolicy ,setShowShareModal )
    };

    return (
        <ApplicationContext.Provider value={{
            setShowSignUpModal,
            setShowSubscribeModal,
            sharePolicy,
            setShowShareModal,
            setShowWaitForVerificationModal
        }}>
            <WaitForVerificationModal show={showWaitForVerificationModal}/>
            <SignUpModal show={showSignUpModal} title={signUpModalTitle} />
            <SubscribeModal show={showSubscribeModal} title={subscribeModalTitle} />
            {shareModalPolicy && <SharePolicyModal show={showShareModal} policy={shareModalPolicy} />}
            <Outlet />
        </ApplicationContext.Provider>
    );
};
