import React, { useEffect, useMemo, useState } from "react"
import { Link } from 'react-router-dom'
import { gradeLetterFromScore, textColorFromScore } from '../utils'
import "../policyIconList.css"

export default function PolicyIconList({policies}) {
    
    const policiesToDisplay = useMemo(() => policies.filter(elem => elem.service_favicon).slice(0, 8), policies)
    const [scrollIncrement, setScrollIncrement] = useState(1)

    const PolicyIcon = ({ policy, isInverted, padding, className, ...props }) => {
        const defualtState = isInverted ? 200 : -200
        const [scroll, setScroll] = React.useState(defualtState)

        useEffect(() => {
            
            setScroll(scroll => scroll + (isInverted ? -padding : padding))  
            const interval = setInterval(() => {
                setScroll(scroll => scroll + (isInverted ? -scrollIncrement : scrollIncrement))
            }
            , 50)
            return () => clearInterval(interval)
        }, [scrollIncrement])

        // if the scroll is greater than the width of the container, reset the scroll to 0
        useEffect(() => {
            if (isInverted && scroll < -200) {
                setScroll(defualtState)
            } else if ((scroll) > 200) {
                setScroll(defualtState)
                
            }
        }, [scroll])

        return (
            <div className={"d-flex flex-row align-items-center tw-h-12 tw-w-12 rounded-pill tw-group shadow tw-absolute "  + className} style={{transform: `translate(${scroll}px)`}} {...props} >
                <div className=" group-hover:tw-opacity-0 tw-transition-opacity tw-absolute">
                    <Link className="tw-flex tw-justify-center tw-h-12 tw-w-12 tw-items-center rounded-pill tw-overflow-clip border border-4 border-white" to={"/policy/" + policy.service}>
                        <img className="tw-h-[115%] tw-w-auto tw-hover:tw-scale-125" src={policy.service_favicon} style={{ objectFit: 'cover', clip: 'rect(0px, 20px, 20px, 0px)' }}></img>
                    </Link>
                </div>
                <div className="tw-opacity-0 group-hover:tw-opacity-100 tw-h-12 tw-w-12 bg-body-teritrary rounded-pill tw-absolute tw-transition-opacity" >
                    <Link className="tw-flex tw-justify-center tw-h-full tw-w-full tw-items-center rounded-pill tw-overflow-clip border border-4 border-white tw-no-underline" to={"/policy/" + policy.service}>
                        <p className={"mb-0 tw-font-bold tw-text-xl " + textColorFromScore(policy.score)}>
                            {gradeLetterFromScore(policy.score)}
                        </p>
                    </Link>
                </div>
            </div>
        )
    }

    const handleMouseEnter = () => {
        //make the animation stop
        // setIsScrolling(!isScrolling)
        // setScrollIncrement(scrollIncrement == 0 ?  : 0)
    }
  return (
    <>
        <div onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseEnter()} className="d-flex flex-column align-items-center justify-content-center tw-w-96 tw-overflow-hidden ol position-relative pb-3">
            
                <div className="d-flex flex-row tw-h-12 tw-relative">
                    <div className="tw-relative">
                        {policiesToDisplay.map((policy, i) => <PolicyIcon padding={i*50}  key={i} policy={policy} />)}
                    </div>
                </div>
                <div className="d-flex flex-row tw-h-12 tw-relative">
                    <div className="tw-relative">
                        {policiesToDisplay.map((policy, i) => <PolicyIcon isInverted={true} padding={i*50}  key={i} policy={policy} />)}
                    </div>
                </div>
                <div className="d-flex flex-row tw-h-12 tw-relative">
                    <div className="tw-relative">
                        {policiesToDisplay.map((policy, i) => <PolicyIcon padding={i*50}  key={i} policy={policy} />)}
                    </div>
                </div>
                {/* <div className="d-flex flex-row tw-h-12 tw-relative">
                    <div className="tw-relative">
                        {policiesToDisplay.map((policy, i) => <PolicyIcon isInverted={true} padding={i*50}  key={i} policy={policy} />)}
                    </div>
                </div> */}
                {/* <div className="d-flex flex-row tw-h-12 tw-relative">
                    <div className="d-flex flex-row gap-2 tw-absolute" style={{transform: `translate(${-scroll}px)`}}>
                        {policiesToDisplay.map((policy, i) => <PolicyIcon key={i} policy={policy} />)}
                    </div>
                </div>
                <div className="d-flex flex-row tw-h-12 tw-relative">
                    <div className="d-flex flex-row gap-2 tw-absolute" style={{transform: `translate(${scroll}px)`}}>
                        {policiesToDisplay.map((policy, i) => <PolicyIcon key={i} policy={policy} />)}
                    </div>
                </div> */}
                
                
            </div>
        
        
    </>
  )
}
