import React, { useContext, useMemo, useState } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"
import * as Api from "../Api"
import { AuthContext } from "../providers/AuthProvider"
import ProSubscriptionFeatures from "./ProSubscriptionFeatures"

import arrow_left_swirly from "../img/swirl-arrow-left-icon.svg"

export default function SubscribeForm({ back, title, intent }) {
  const { refreshUser } = useContext(AuthContext)
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [period, setPeriod] = useState("monthly")

  const fixedIntent = useMemo(() => intent == "subscribe" && !loading ? "subscribe" : "signup", [intent, loading])

  const navigate = useNavigate()

  const validateEmail = (email) => {
    return email.length > 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (fixedIntent == "signup") {
      setMessage(null)

      const formData = new FormData(e.target)
      const email = formData.get("email")
      const password = formData.get("password")
      const confirmPassword = formData.get("confirm_password")

      if (!validateEmail(email)) return setMessage("Invalid email")
      if (password.length < 6) return setMessage("Password too short (min 6 characters")
      if (password !== confirmPassword) return setMessage("Passwords do not match")

      setLoading(true)

      try {
        const res = await Api.register(email, password)
        if (res.success) {
          await refreshUser()
          // navigate("/account")
          Api.createCheckoutSession("pro", period)
        }
      }
      catch (err) {
        setMessage(err.message)
        setLoading(false)
      }
    }
    else if (fixedIntent == "subscribe") {
      Api.createCheckoutSession("pro", period)
    }
  }

  const spinner = (
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )

  const onPeriodSwitchChange = e => {
    setPeriod(e.target.checked ? "yearly" : "monthly")
  }

  return (
    <div className="rounded-2 bg-body-tertiary border px-4 pt-4 pb-3 w-100 tw-max-w-sm">
      <form className="d-flex flex-column w-100" onSubmit={handleSubmit}>
        <div className="d-flex flex-row">
          {back && <Link onClick={() => window.history.back()}>
            <i className="btn bi bi-arrow-left-circle-fill fs-5 px-1 me-2"></i>
          </Link>}
          <h1 className="font-bold h2">{title || "Subscribe"}</h1>
        </div>
        <h5 className="text-body-secondary">From <span className="text-primary">$2/mo</span><img className="px-2" src={arrow_left_swirly} height={15}/><span className="d-inline-block" style={{ transform: "rotate(-5deg) translateY(-7px)" }}>Just 1 <i className="bi bi-cup-hot"></i>/month!</span></h5>
        {
          fixedIntent == "signup"
            ?
            (
              <>
                <div className="d-flex flex-column gap-2">
                  <div className="">
                    <div className="form-floating">
                      <input name="email" type="email" className="form-control" placeholder="name@example.com" />
                      <label htmlFor="floatingInput">Email</label>
                    </div>
                  </div>
                  <div className="">
                    <div className="form-floating">
                      <input name="password" type="password" className="form-control" placeholder="Password" />
                      <label htmlFor="floatingPassword">Password</label>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="form-floating">
                      <input name="confirm_password" type="password" className="form-control" placeholder="Password" />
                      <label htmlFor="floatingPassword">Confirm Password</label>
                    </div>
                  </div>
                </div>
                {message && <div className="tw-min-h-6">
                  <p className=" tw-text-red-400 mt-0 mb-0 tw-text-wrap tw-max-w-80">{message}</p>
                </div>}
              </>
            )
            :
            <div className="px-1 pt-3 pb-1">
              <ProSubscriptionFeatures />
            </div>
        }
        <div className="w-100">
          <div className="form-check form-switch mb-2">
            <input onChange={onPeriodSwitchChange} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
            <label className="form-check-label" for="flexSwitchCheckDefault">Pay Yearly <small className="text-warning bg-warning-subtle border border-warning rounded-2 px-2 fw-bold">Save 33%</small></label>
          </div>

          <button type="submit" className="w-100 btn btn-primary">{
            loading ?
              spinner
              :
              <span>Subscribe for <b>${period == "monthly" ? "3" : "2"}/mo</b></span>
          }
          </button>
        </div>
        <span className="mt-1 text-center text-secondary">{fixedIntent == "signup" ? "Upon signing up, you will be redirected to our checkout page." : "You will be redirected to our checkout page."}</span>
        {/* <span className="mt-1 text-center text-secondary">Forgot password? <span><Link to="/login">Reset</Link></span></span> */}
      </form >
      {/* <div className="tw-min-w-xl">
        <h2 className="h3">With an account:</h2>
        <ul className="text-body-secondary fs-5 list-unstyled">
          <li><span>Ask unlimited questions</span></li>
          <li><span>Get notified</span></li>
          <li><span>Save bookmarks across devices</span></li>
        </ul>
      </div> */}
    </div >
  )
}

