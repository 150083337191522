import html2canvas from 'html2canvas'
import { getUser } from './Api';

export function capitalizeFirstLetter(string) {
    if (typeof string !== 'string' || string.length === 0) {
        return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeAllWords(string) {
    if (typeof string !== 'string' || string.length === 0) {
        return string;
    }

    return string
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function textColorFromScore(score) {
    // if (score <= 0) {
    //     return "text-body-secondary"
    // } else if (score <= 1) {
    //     return "text-body-secondary"
    // } else if (score <= 2) {
    //     return "text-warning"
    // } else {
    //     return "text-danger"
    // }
    if (score <= 0.25) {
        return "text-body-tertiary"
    } else if (score <= 0.7) {
        return "text-body-tertiary"
    } else if (score <= 1.2) {
        return "text-body-secondary"
    } else if (score <= 1.7) {
        return "text-body"
    } else if (score <= 2.2) {
        return "text-body"
    } else if (score <= 2.7) {
        return "text-danger text-opacity-50"
    } else {
        return "text-danger"
    }
}

export function gradeLetterFromScore(score) {
    if (score <= 0.25) {
        return "A"
    } else if (score <= 0.7) {
        return "A"
    } else if (score <= 1.2) {
        return "B"
    } else if (score <= 1.7) {
        return "C"
    } else if (score <= 2.2) {
        return "D"
    } else if (score <= 2.7) {
        return "E"
    } else {
        return "F"
    }
}

export function bgColorFromScore(score) {
    if (score <= 0) {
        return "bg-body-tertiary"
    } else if (score <= 1) {
        return "bg-body-secondary"
    } else if (score <= 2) {
        return "bg-warning"
    } else {
        return "bg-danger"
    }
}

export function getBehaviourInList(behaviours, behaviourType) {
    if (!behaviours) return false
    return behaviours.find(x => x.type === behaviourType)
}

export function iconFromBehaviourScore(score) {
    if (score == 3) return "bi-exclamation-triangle"
    else if (score == 2) return "bi-exclamation-triangle"
    else if (score == 1) return "bi-info-circle"
}

export function bgColorFromBehaviourScore(score) {
    if (score == 3) return "bg-danger-subtle"
    else if (score == 2) return "bg-warning-subtle"
    else if (score == 1) return "bg-info-subtle"
}

export function textColorFromBehaviourScore(score) {
    if (score == 3) return "text-danger-emphasis"
    else if (score == 2) return "text-warning-emphasis"
    else if (score == 1) return "text-info-emphasis"
}

export const exportAsImage = async (element) => {
    const canvas = await html2canvas(element)
    const image = canvas.toDataURL("image/png", 1.0)
    downloadImage(image)
};

export const downloadImage = (blob) => {
    const fakeLink = window.document.createElement("a")
    fakeLink.style = "display:none;"
    fakeLink.download = 'component-image.png'

    fakeLink.href = blob

    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)

    fakeLink.remove()
};

export const getImage = async (element) => {
    const canvas = await html2canvas(element)
    const image = canvas.toDataURL("image/png", 1.0)
    return image
}

export const parseDate = (date) => {
    let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ]
    const formattedDate = months[date.getMonth()] + " " + date.getDate()
    return formattedDate
}

export const handleShareLogic = (policy, setShareModalPolicy, setShowShareModal) => {

    const shareLink = `${window.location.origin}/policy/${policy.service}`
    const shareTitle = `Policy`
    const shareText = `${policy.service} has a privacy score of ${gradeLetterFromScore(policy.score)}!\n\nPrivacy Police reads privacy policies so you don't have to:`

    const shareData = {
        title: shareTitle,
        text: shareText,
        url: encodeURI(shareLink),
    }

    const isShareable = !!navigator.canShare ? navigator.canShare(shareData) : false

    if (isShareable) {
        navigator.share(shareData)
    } else {
        setShareModalPolicy(policy)
        setShowShareModal(true)
    }
}

export const waitTillVerified = async (timeout) => {
    return new Promise((resolve, reject) => {
        const check = async () => {
            try {
                const { user } = await getUser()
                if (user.verified) {
                    resolve()
                    return
                }
                setTimeout(check, timeout)
            }
            catch (err) {
                reject(err)
            }
        }
        check()
    })
}

export const getCurrentEvaluation = (evaluations) => {
    return evaluations.reduce((latest, evaluation) => {
        return (new Date(latest.scannedDate) > new Date(evaluation.scannedDate)) ? latest : evaluation;
    }, evaluations[0])
}