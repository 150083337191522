import { useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { Link, Navigate, useLoaderData } from "react-router-dom"
import { ApplicationContext } from "../providers/ApplicationProvider"
import SmallHeading from "../components/SmallHeading"
import Section from "../components/Section"
import { ProgressBar } from "react-bootstrap"
import ConfirmModal from "../modals/ConfirmModal"
import PolicyCardList from "../components/PolicyCardList"
import { ScanPolicyContext } from "../providers/ScanPolicyProvider"
import { getScans } from "../Api"
import { Helmet } from "react-helmet"
import PageTitle from "../components/seo/PageTitle"


export async function loader({ }) {
    const res = await getScans()
    return res;
}

export default function ScanPolicyPage({ }) {
    const { policies } = useLoaderData()
    const { scanPolicy } = useContext(ScanPolicyContext)

    const { user, isGuestUser, isFreeUser, isPaidUser } = useContext(AuthContext)

    const inputRef = useRef(null)

    const onSubmit = e => {
        e.preventDefault()
        const url = inputRef.current.value
        scanPolicy(url)
    }

    const UserPolicyEntry = ({ policy }) => {
        const to = `/policy/${policy.user_content_id}`
        return (
            <div className="border-end border-bottom rounded-4 bg-body shadow-sm w-100 tw-group">
                <div className="h-100 d-flex flex-row justify-content-between position-relative">
                    <div className="p-3">
                        <h5 className="fw-bold">{policy.service}</h5>
                    </div>
                    <div className="p-3">
                        <Link to={to}><i className="bi bi-arrow-right stretched-link"></i></Link>
                    </div>
                </div>
            </div>
        )
    }

    if (user && !isPaidUser) return <Navigate to={"/#pricing"} replace />

    return (
        <div className="tw-min-h-[80vh] bg-body-tertiary">
                <PageTitle sub={"Scan Policy"}/>
            <SmallHeading title={"Scan Policy"}>
                <form onSubmit={onSubmit} className="d-flex flex-row justify-content-center gap-2">
                    <div className="tw-max-w-sm w-100">
                        <input
                            defaultValue={"https://privacypolice.ai/legal/privacy-policy"}
                            type="url"
                            ref={inputRef}
                            placeholder={"Policy URL"}
                            className="w-100 rounded-pill border px-3 py-2 bg-body-secondary text-body-secondary"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary rounded-pill px-3 fw-bold">Scan</button>
                </form>
            </SmallHeading>
            <Section className={"bg-body-tertiary"} paddingBottom={0}>
                <h2>Using the Tool</h2>
                <ul className="fs-5">
                    <li>Ideally, enter the full URL pointing to the privacy policy, not the main website.</li>
                    <li>Some websites use technologies to block bots, which may include our scanner.</li>
                    <li>Some privacy policy texts could be missed if they are behind an <a href="https://blog.hubspot.com/website/accordion-design">accordion</a> or similar component that needs to be clicked to reveal the text.</li>
                    <li>Do not use the tool for legal advice :-)</li>
                </ul>
            </Section>
            <Section className={"bg-body-tertiary"}>
                {policies.length > 0 && <h3>Recent Scans</h3>}
                <PolicyCardList policies={policies} />
            </Section>
        </div>
    )
}