import { gradeLetterFromScore, getBehaviourInList, textColorFromScore, textColorFromBehaviourScore, bgColorFromBehaviourScore, iconFromBehaviourScore, getCurrentEvaluation } from "../utils"
import { Link } from "react-router-dom"
import RatingBar from "./RatingBar"
import BehaviourNotice from "./BehaviourNotice"

export const PolicyCardBase = ({ policy, link, interactive, children }) => {
    const TextItem = ({ information, i }) => {
        return (
            <li key={information.type + i} className={"d-flex flex-row gap-3 justify-content-between"}>{information.title}<RatingBar score={information.score} /></li>
        )
    }

    const evaluation = getCurrentEvaluation(policy.evaluations)

    const sellsUserData = getBehaviourInList(evaluation.behaviours, "sells_user_data")
    const sharesUserDataWithAdvertisers = getBehaviourInList(evaluation.behaviours, "shares_user_data_for_money")
    const sharesUserData = getBehaviourInList(evaluation.behaviours, "shares_user_data")

    const interactiveRatingClassNames = interactive ? "bg-body" : "bg-body-secondary"


    return (
        <div className="border-end border-bottom rounded-4 bg-body shadow-sm tw-w-80 tw-group" style={{ minHeight: "211px", pointerEvents: (link ? "unset" : "none"), userSelect: (link ? "unset" : "none") }}>
            <div className="h-100 d-flex flex-column justify-content-between">
                <div className="position-relative p-3 pb-0 h-100">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row align-items-center mb-2">
                            {
                                policy.service_favicon
                                    ?
                                    <img className="d-inline-block flex-shrink-1 me-1" width={20} src={policy.service_favicon}></img>
                                    :
                                    <div className="rounded-circle tw-w-5 tw-h-5 bg-primary  me-1 d-flex justify-content-center align-items-center w-full"><span className="text-white tw-text-xs">{policy.service[0].toUpperCase()}</span></div>
                            }

                            <h6 className="h5 mb-0 fw-bold me-2">{policy.service}</h6>
                            {!policy.advertisement && sellsUserData && <BehaviourNotice behaviour={sellsUserData} title={"May sell data"} interactive={interactive} />}
                            {!policy.advertisement && !sellsUserData && sharesUserDataWithAdvertisers && <BehaviourNotice behaviour={sharesUserDataWithAdvertisers} title={"Ad-sharing"} interactive={interactive} />}
                            {!policy.advertisement && !sellsUserData && !sharesUserDataWithAdvertisers && sharesUserData && <BehaviourNotice behaviour={sharesUserData} title={"May share data"} interactive={interactive} />}
                            {policy.advertisement && policy.advertisement_special && <BehaviourNotice behaviour={{ score: 1 }} title={policy.advertisement_special} interactive={false} />}
                        </div>
                        <Link style={{ textDecoration: "none" }}
                            className={"fs-5 stretched-link d-flex justify-content-center align-items-center bg-body-tertiary tw-w-5 tw-h-5 rounded-2 " + textColorFromScore(policy.score)}
                            to={link}>
                            {gradeLetterFromScore(policy.score)}
                        </Link>

                    </div>
                    <ul className="list-unstyled mb-0 mx-1">
                        {evaluation.information_collected.sort((a, b) => b.score - a.score).slice(0, 5).map((information, i) => <TextItem key={i} information={information} i={i} />)}
                    </ul>
                </div>
                {children}
            </div>
        </div>
    )
}