import { Link } from "react-router-dom";
import Section from "../components/Section";
import SmallHeading from "../components/SmallHeading";

export default function TermsOfServicePage({ }) {
    return (
        <div>
            <SmallHeading title={"Terms of Service"} helmet={true}/>
            <Section className="fs-5 bg-body-tertiary">
                <div className="tw-max-w-4xl">
                    <p>Thank you for using Privacy Police! By using our services, you agree to the following terms and conditions. Please read them carefully.</p>

                    <h2>1. Subscription Plan</h2>
                    <p>We offer a subscription plan that is billed on a monthly basis. By subscribing, you agree to pay the subscription fee for the duration of the month in which you are subscribed.</p>

                    <h3>a. Cancellation Policy</h3>
                    <p>You can cancel your subscription at any time. Upon cancellation, your subscription will remain active until the end of the current billing cycle. You will not be charged for subsequent months unless you re-subscribe.</p>

                    <h2>2. Subscription Benefits</h2>
                    <p>As a subscribed user of Privacy Police, you will have access to the following benefits:</p>

                    <h3>a. Access to AI Assistant</h3>
                    <p>Your subscription grants you access to our AI assistant, allowing you to ask questions related to privacy policies at any time.</p>

                    <h3>b. Scan Custom Policy</h3>
                    <p>
                        Your subscription gives you the ability to scan custom policies that do not exist yet in our list.
                        You input the privacy policy URL to the tool, and it will create a privacy rating and a
                        list of collected information just like in our existing list.
                        Your scanned privacy policy will not appear in the policy list for other users, but you may share the direct link to it.
                    </p>

                    <h3>c. No Advertisements, Ever</h3>
                    <p>Your subscription will remove any advertisements on our site when you are logged in. This will continue to be the case even after your subscription has ended. Subscribe once and get an ad-free experience forever.</p>

                    <h2>3. User Responsibilities</h2>
                    <p>By using Privacy Police, you agree to:</p>
                    <ul>
                        <li>Provide accurate and current information when creating an account.</li>
                        <li>Not use our services for any illegal or unauthorized purposes.</li>
                        <li>Respect the intellectual property rights of Privacy Police and other users.</li>
                        <li>Acknowledge and agree that the information, services, and materials provided by Privacy Police are for informational purposes only and shall not be construed as legal advice, guidance, or counsel. You further agree that no attorney-client relationship is established between you and Privacy Police, and you shall not rely on any information obtained from our services for any legal, business, or financial decision-making.</li>
                    </ul>

                    <h2>4. Termination of Service</h2>
                    <p>Privacy Police reserves the right to terminate or suspend your account at any time if you violate these Terms of Service or engage in any activity that we deem harmful to our service or other users.</p>

                    <h2>5. Changes to Terms of Service</h2>
                    <p>We may update these Terms of Service from time to time. Any changes will be posted on this page with an updated effective date. Continued use of our services after any changes constitutes acceptance of the new terms.</p>

                    <h2>6. Contact Us</h2>
                    <p>If you have any questions about these Terms of Service, please contact us at:</p>
                    <p><strong>Email:</strong> support@privacypolice.ai</p>
                    <p>By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>
                </div>
            </Section>
        </div>
    )
}