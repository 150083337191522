import { useContext, useMemo } from "react"
import { capitalizeFirstLetter, gradeLetterFromScore, getBehaviourInList, textColorFromScore, capitalizeAllWords } from "../utils"
import { Link, useNavigate } from "react-router-dom"
import RatingBar from "./RatingBar"
import { UserSettingsContext } from "../providers/UserSettingsProvider"
import { ApplicationContext } from "../providers/ApplicationProvider"
import { AuthContext } from "../providers/AuthProvider"
import { PolicyCardBase } from "./PolicyCardBase"

export default function PolicyCard({ policy, showCategory }) {

    const { setShowSignUpModal, sharePolicy, setShowSubscribeModal } = useContext(ApplicationContext)
    const { isBookmarked, addBookmark, removeBookmark, isNotification, addNotification, removeNotification } = useContext(UserSettingsContext)
    const { user, isGuestUser } = useContext(AuthContext)

    const { advertisement } = policy
    const link = advertisement ? policy.service_url : `/policy/${policy.service}`

    const navigate = useNavigate()

    const onShareBtnClicked = () => {
        sharePolicy(policy)
    }

    const onChatBtnClicked = () => {
        if (isGuestUser) {
            setShowSubscribeModal(true, "Subscribe to Chat")
        }
        else {
            navigate(link, { state: { chat: true } })
        }
    }

    const toggleBookmark = () => {
        if (isBookmarked(policy.service)) {
            removeBookmark(policy.service)
        }
        else {
            addBookmark(policy.service)
        }
    }

    const toggleNotification = () => {
        if (isGuestUser) {
            setShowSignUpModal(true, "Sign Up to Get Notified")
            return
        }
        if (isNotification(policy.service)) {
            removeNotification(policy.service)
        }
        else {
            addNotification(policy.service)
        }
    }

    return (
        <PolicyCardBase policy={policy} interactive={true} link={link}>
            <div className="d-flex flex-row justify-content-between p-2 px-2">
                <div className="text-body-tertiary d-flex flex-row align-items-end ps-2" style={{ userSelect: "none" }}>
                    {
                        advertisement ?
                            <small>
                                {/* {!showCategory && isBookmarked(policy.service) && <i className="bi bi-bookmark me-1"></i>} */}
                                <Link
                                    className="text-body-tertiary link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
                                    to={`/feature-policy`}
                                    style={{ textDecoration: "none" }}>
                                    <i className="bi bi-patch-check-fill me-1"></i>Promoted
                                </Link>
                            </small>
                            :
                            <small>
                                {/* {!showCategory && isBookmarked(policy.service) && <i className="bi bi-bookmark me-1"></i>} */}
                                {showCategory && <Link
                                    className="text-body-tertiary link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
                                    to={`/policies/${policy.category}`}
                                    style={{ textDecoration: "none" }}>
                                    <i className="bi bi-trophy me-1"></i>Best in {capitalizeAllWords(policy.category)}
                                </Link>}
                            </small>
                    }
                </div>
                <div className="d-flex flex-row gap-1 group-hover:tw-opacity-100 tw-opacity-0 tw-transition-opacity">
                    <button className="btn btn-sm border rounded-3" onClick={onShareBtnClicked}><i className="bi bi-share-fill"></i></button>
                    {!advertisement && <button className="btn btn-sm border rounded-3" onClick={onChatBtnClicked}><i className="bi bi-chat"></i></button>}
                    {advertisement && <Link className="btn btn-sm border rounded-3" to={link}><i className="bi bi-box-arrow-up-right"></i></Link>}
                    {/* <button
                        onClick={() => toggleNotification()}
                        className={"btn btn-sm border rounded-3 " + (isNotification(policy.service) && "text-primary")}>
                        <i className={"bi " + (isNotification(policy.service) ? "bi-bell-slash-fill" : "bi-bell")}></i>
                    </button> */}
                    {/* <button
                        onClick={() => toggleNotification()}
                        className={"btn btn-sm border rounded-3 " + (isNotification(policy.service) && "text-primary")}>
                        <i className={"bi " + (isNotification(policy.service) ? "bi-bell-fill" : "bi-bell")}></i>
                    </button> */}
                </div>
            </div>
        </PolicyCardBase>
    )
}