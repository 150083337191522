import { useContext } from "react"
import { AuthContext } from "../providers/AuthProvider"
import QuestionMark from "./QuestionMark"
import { Link } from "react-router-dom"
import { API_URL } from "../Api"
import SubscribeFormButton from "./SubscribeFormButton"
import ProSubscriptionFeatures from "./ProSubscriptionFeatures"

export default function PricingCards({ isYearly }) {
    const { user, isGuestUser } = useContext(AuthContext)

    const isPro = user && user.plan === "pro"
    const isFree = isGuestUser || !isPro

    const mark = <small className="ms-1 bg-primary-subtle rounded border border-primary p-1 text-primary fs-5">Current</small>

    return (
        <div className="d-flex flex-column flex-sm-row justify-content-center gap-4 mb-3 text-center">
            <div className="col tw-max-w-xs mx-auto w-100 mx-sm-0">
                <div className="card mb-4 tw-h-full rounded-3 border shadow-sm">
                    <div className="card-header border-0 py-3">
                        <h4 className="my-0 fw-bold">Free {isFree && mark}</h4>
                    </div>
                    <div className="card-body">
                        <h1 className="card-title pricing-card-title">$0<small className="text-body-secondary fw-light">/mo</small></h1>
                        <ul className="list-unstyled text-start mt-3 mb-4 text-secondary">
                            <li><i className="bi bi-patch-exclamation-fill me-1"></i>Limited advertisements</li>
                            <li><i className="bi bi-patch-exclamation-fill me-1"></i>Scan one policy per 24h</li>
                            <li><i className="bi bi-patch-exclamation-fill me-1"></i>Only read existing questions</li>
                            <li><i className="bi bi-patch-exclamation-fill me-1"></i>Only view existing policies</li>
                        </ul>
                    </div>
                    <div className="card-footer border-0">
                        <Link type="button" to="/policies" className="w-100 btn btn-lg btn-outline-primary">{isGuestUser ? "Sign Up" : "View Policies"}</Link>
                    </div>
                </div>
            </div>
            <div className="col tw-max-w-xs mx-auto w-100 mx-sm-0">
                <div className="card tw-h-full rounded-3 border shadow border-primary">
                    <div className="card-header py-3 text-primary ">
                        <h4 className="my-0 fw-bold">Pro {isPro && mark}</h4>
                    </div>
                    <div className="card-body">
                        {isYearly
                            ?
                            <div className="d-flex flex-column justify-content-center align-items-center ">
                                <h1 className="card-title pricing-card-title mb-0">$2<small className="text-body-secondary fw-light">/mo</small> </h1>
                                <p className="text-secondary mb-1"><small>$24 /year</small></p>
                                <p className="tw-w-fit bg-warning-subtle rounded border border-warning px-1 text-warning fs-6 mb-0">You save <strong>$12</strong>/year</p>
                            </div>
                            :
                            (<h1 className="card-title pricing-card-title">$3<small className="text-body-secondary fw-light">/mo</small></h1>)
                        }
                        <div className="pt-3">
                            <ProSubscriptionFeatures />
                        </div>
                    </div>
                    <div className="card-footer border-0">
                        <SubscribeFormButton period={isYearly ? "yearly" : "monthly"} buttonClassName={"w-100 btn btn-lg btn-primary fw-bold"} />
                    </div>
                </div>
            </div>
        </div>
    )
}