import { useContext, useRef, useState } from "react"
import { Modal } from 'react-bootstrap'
import { AuthContext } from "../providers/AuthProvider"
import { ScanPolicyContext } from "../providers/ScanPolicyProvider"
import { requestPendingScanLink } from "../Api"

export default function VerifyEmailModal({ show, url, title }) {
    const { setShowVerifyEmailModal } = useContext(ScanPolicyContext)

    const [loading, setLoading] = useState(false)
    const [successEmail, setSuccessEmail] = useState(null)
    const [error, setError] = useState(null)
    const emailFieldRef = useRef()

    const spinner = (
        <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )

    const onCancel = () => {
        setShowVerifyEmailModal(false)
    }

    const onConfirm = () => {

    }

    const _onSubmit = async e => {
        e.preventDefault()
        setLoading(true)

        try {
            const res = await requestPendingScanLink(emailFieldRef.current.value, url)
            if (res.success) {
                setError(null)
                setSuccessEmail(emailFieldRef.current.value)
            }
        }
        catch (err) {
            setError(err.message)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Modal dialogClassName="tw-max-w-sm" show={show} centered onHide={onCancel}>
                <div className="p-4 fs-5">
                    <form onSubmit={_onSubmit}>
                        <h2>Verify email.</h2>
                        <p>To prevent spam, you need to verify your email address before scanning.</p>
                        { error && <p className="text-danger fs-6">{error}</p> }
                        <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                            <label htmlFor="email" className="visually-hidden">Email address</label>
                            <input disabled={successEmail} ref={emailFieldRef} id="email" value={successEmail ? "Check your email! :-)" : undefined} type="email" className="form-control" placeholder="Email address" />
                            <button disabled={loading || successEmail} className="btn btn-primary" type="submit">{successEmail ? <i className="bi bi-check-lg"></i> : (loading ? spinner : "Verify")}</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>

    )
}