import { Navigate } from "react-router-dom"

export default function VerifyScanPage({ }) {
    const encodedToken = window.location.hash ? window.location.hash.slice(1) : null

    if(encodedToken) {
        const [scanTokenUrl, scanToken] = atob(encodedToken).split(" ")
        return <Navigate to={"/"} replace state={{
            scanToken,
            scanTokenUrl
        }}/>
    }

    return <Navigate to={"/"} replace/>
}