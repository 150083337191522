import QuestionMark from "./QuestionMark";

const removeAdsForeverTooltip = (
    <span>If you purchase a plan, your account will <b>never-ever</b> see ads again, even if you cancel your subscription.</span>
)

const askUnlimitedQuestionsTooltip = (
    <span>Ask questions to our AI assistant about any privacy policy, even your own custom scans.</span>
)

const scanPolicyTooltip = (
    <span>Submit policies to instantly be evaluated and ranked.</span>
)

export default function ProSubscriptionFeatures({ }) {
    return (
        <ul className="list-unstyled text-start">
            <li><i className="text-body bi bi-radar me-1"></i><b>Scan unlimited policies<QuestionMark>{scanPolicyTooltip}</QuestionMark></b></li>
            <li><i className="text-body bi bi-chat me-1"></i><b>Ask unlimited questions</b><QuestionMark>{askUnlimitedQuestionsTooltip}</QuestionMark></li>
            <li><i className="text-success bi bi-check-lg me-1"></i><b>Ad-free forever</b><QuestionMark>{removeAdsForeverTooltip}</QuestionMark></li>
            {/* <li><i className="text-success bi bi-calendar-heart me-1"></i>Instant access to new features</li> */}
            <li><i className="text-danger bi bi-x-octagon me-1"></i>No hidden fees, cancel any time</li>
            <li><i className="text-body bi bi-cup-hot me-1"></i>Support the team</li>
        </ul>
    )
}