import { useContext, useState } from "react"
import { Modal } from 'react-bootstrap'
import { ScanPolicyContext } from "../providers/ScanPolicyProvider"
import Cross from "../components/Cross"

export default function ScanPolicyModal({ show, title, url, progress, error }) {
    const { setShowScanPolicyModal } = useContext(ScanPolicyContext)

    const onCancel = () => {
        setShowScanPolicyModal(false)
    }

    const onConfirm = () => {

    }

    const spinnersm = (
        <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )

    const spinnermd = (
        <div className="spinner-border spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )

    const ProgressEntry = ({ progressObj }) => {
        const { type, title, progress, result } = progressObj
        const done = progress === 100

        const resultIcon = result ? "bi-exclamation-lg" : "bi-check"
        const resultColor = result ? "text-danger" : "text-primary"
        const resultBg = result ? "bg-danger-subtle" : "bg-primary-subtle"
        const resultBorder = result ? "border-danger" : "border-primary"
        const resultText = result ? "YES" : "NO"

        const titleColor = type == "information" ? (result ? "text-danger" : "text-tertiary") : "text-body-secondary"

        return (
            <div className="w-100">
                {
                    done ?
                        (
                            type == "information" ?
                                (
                                    <span className="d-flex flex-row justify-content-between">
                                        <span className={`me-2 ${titleColor}`}>{title}</span>
                                        <div className="d-inline-block fs-6">
                                            <span className={`ps-2 pe-3 d-flex flex-row align-items-center rounded-pill border ${resultColor} ${resultBorder} ${resultBg}`}>
                                                <i className={`bi ${resultIcon}`}></i>
                                                <span>{resultText}</span>
                                            </span>
                                        </div>
                                    </span>
                                )
                                :
                                (
                                    <span className="d-flex flex-row justify-content-between">
                                        <span className={`me-2 ${titleColor}`}>{title}</span>
                                        <div className="d-inline-block fs-6">
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                    </span>
                                )
                        )
                        :
                        <span className={titleColor}>{title}... <span className="text-body-tertiary">{spinnersm}</span></span>
                }
            </div>
        )
    }

    const ProgressBox = ({ progress, error }) => {
        return (
            <div className="rounded-3 overflow-hidden fs-5 text-tertiary">
                {progress.map(x => <ProgressEntry key={x.title} progressObj={x} />)}
            </div>
        )
    }

    return (
        <>
            <Modal backdrop={"static"} dialogClassName="tw-max-w-md" show={show} centered>
                <div className="p-4">
                    {!error && <h2 className="fw-bold">Scanning policy... </h2>}

                    {
                        error ?
                            <div>
                                <div className="">
                                    {/* <Cross className={"text-danger m-2"} /> */}
                                    <h2 className="text-danger fw-bold">Error while scanning:</h2>
                                </div>
                                <p className="fs-5">
                                    {error}
                                </p>
                                <span className="fs-5 text-body-secondary d-flex flex-row justify-content-between align-items-center">
                                    This scan did not count to your total.
                                    <button className="btn btn-primary px-3 fw-bold" onClick={onCancel}>OK</button>
                                </span>
                            </div>
                            :
                            <ProgressBox progress={progress} error={error} />
                    }

                </div>

            </Modal>
        </>
    )
}