import { createContext, useContext, useState } from "react"
import * as Api from "../Api"
import { Outlet } from "react-router-dom"
import ScanPolicyModal from "../modals/ScanPolicyModal"
import { AuthContext } from "./AuthProvider"
import VerifyEmailModal from "../modals/VerifyEmailModal"

const TEST_PROGRESS = [
    {
        type: 'information',
        title: "The user's age",
        progress: 100,
        result: false
    },
    {
        type: 'information',
        title: "The user's age2",
        progress: 100,
        result: true
    },
    { type: 'information', title: 'Messages (metadata)', progress: 100, result: true },
    { type: 'information', title: 'Location (regional)', progress: 0 },
]

export const ScanPolicyContext = createContext({})

export const ScanPolicyProvider = ({ }) => {
    const { user, isGuestUser } = useContext(AuthContext)

    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false)
    const [showScanPolicyModal, setShowScanPolicyModal] = useState(false)

    const [policyUrl, setPolicyUrl] = useState(null)
    const [progress, setProgress] = useState([])
    const [error, setError] = useState(null)

    const displayError = (error) => {
        setError(error)
        setShowScanPolicyModal(true)
    }

    const scanPolicy = (url, scanToken) => {
        setPolicyUrl(url)
        // if (!scanToken && isGuestUser) {
        //     // Button pressed, no scan token, need to send email to scan
            
        // }
        setError(null)
        setShowScanPolicyModal(true)

        // Reset progress
        setProgress([])

        // const parsedUrl = new URL(url)
        // if(parsedUrl.pathname.length < 5) {
        //     return displayError("This URL does not seem like a privacy policy. Please include the full path to the policy like 'https://x.com/en/privacy'")
        // }

        Api.scanPolicy(scanToken, url, progressObj => {
            const { title, progress, result } = progressObj

            setProgress(prevProgress => {
                // Check if the progress for the title already exists
                const existingProgressObj = prevProgress.find(x => x.title === title)

                if (existingProgressObj) {
                    // Update the existing progress object immutably
                    return prevProgress.map(x =>
                        x.title === title ? { ...x, progress, result } : x
                    )
                } else {
                    // Add new progress object to the list
                    return [...prevProgress, progressObj]
                }
            })
        })
            .then(res => {
                if (res.needScanToken) {
                    setShowVerifyEmailModal(true)
                    setShowScanPolicyModal(false)
                }
                else {
                    window.location.href = `/policy/${res.policy.service}`
                }
            })
            .catch(err => {
                console.error(err)
                setError(err)
                setProgress([])
            })
    }


    return (
        <ScanPolicyContext.Provider value={{
            showScanPolicyModal,
            setShowScanPolicyModal,
            setShowVerifyEmailModal,
            scanPolicy,
        }}>
            {showVerifyEmailModal && <VerifyEmailModal show={showVerifyEmailModal} url={policyUrl} />}
            {showScanPolicyModal && <ScanPolicyModal show={showScanPolicyModal} url={policyUrl} progress={progress} error={error} />}
            <Outlet />
        </ScanPolicyContext.Provider>
    )
}