import { Link, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { dislikePolicy, getCategory, getPolicy, likePolicy, policyChat } from '../Api';
import { capitalizeFirstLetter, gradeLetterFromScore, getBehaviourInList, textColorFromScore, getCurrentEvaluation } from '../utils';
import InformationCard from '../components/InformationCard';
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { ApplicationContext } from "../providers/ApplicationProvider";
import BehaviourCard from "../components/BehaviourCard";
import { Helmet } from "react-helmet";
import PageTitle from "../components/seo/PageTitle";

export async function loader({ params }) {
  const { policy } = await getPolicy(params.policy);
  const { category } = await getCategory(policy.category)
  return { policy, category };
}

export default function PolicyPage() {
  const { policy, category } = useLoaderData()
  const { service, service_url, service_favicon, policy_url, evaluations, score, user_content, is_liked, is_disliked, likes, dislikes } = policy

  const { user, isPaidUser, isGuestUser, isFreeUser } = useContext(AuthContext)
  const { setShowSubscribeModal, setShowSignUpModal, sharePolicy } = useContext(ApplicationContext)

  const { state } = useLocation()

  const [evaluation, setEvaluation] = useState(getCurrentEvaluation(evaluations))

  // Ugly Hack wtf
  useEffect(() => {
    setEvaluation(getCurrentEvaluation(evaluations))
  }, [policy])

  const sortedInformationCollected = useMemo(() => evaluation.information_collected.sort((a, b) => b.score - a.score), [evaluation])
  const filteredInformationCollected = useMemo(() => sortedInformationCollected.filter(entry => entry.type !== "other"), [sortedInformationCollected])
  const otherCount = useMemo(() => evaluation.information_collected.reduce((count, entry) => {
    if (entry.type === "other") {
      return count + 1
    }
    return count
  }, 0), [evaluation])

  const sellsUserData = useMemo(() => getBehaviourInList(evaluation.behaviours, "sells_user_data"), [evaluation])
  const sharesUserDataForMoney = useMemo(() => getBehaviourInList(evaluation.behaviours, "shares_user_data_for_money"), [evaluation])
  const sharesUserData = useMemo(() => getBehaviourInList(evaluation.behaviours, "shares_user_data"), [evaluation])
  const shownBehaviour = sellsUserData || sharesUserDataForMoney || sharesUserData || null

  const toLocaleString = (date) => {
    return date.toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric' })
  }

  const lastUpdatedString = useMemo(() => toLocaleString(new Date(evaluation.scannedDate)), [evaluation])

  const questionInputRef = useRef(null)
  const [chatQuestion, setChatQuestion] = useState(null)
  const [chatAnswer, setChatAnswer] = useState(null)
  const [chatAnswerDone, setChatAnswerDone] = useState(true)
  const [isLiked, setIsLiked] = useState(0)
  const [likeCount, setLikeCount] = useState(0)
  const [dislikeCount, setDislikeCount] = useState(0)

  useEffect(() => {
    setDislikeCount(dislikes)
    setLikeCount(likes)
    if (is_liked) {
      setIsLiked(1)
    } else if (is_disliked) {
      setIsLiked(-1)
    } else {
      setIsLiked(0)
    }
  }, [])

  const SmallInformationLink = ({ information, to }) => {
    return (
      <li><Link className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body"
        to={`#${to}`}
        replace={true}>
        {information.title}
      </Link></li>
    )
  }

  const askQuestion = (question) => {
    setChatQuestion(question)
    setChatAnswerDone(false)
    let _chatAnswer = ""
    setChatAnswer(_chatAnswer)
    questionInputRef.current.value = ""
    policyChat(service, question, data => {
      _chatAnswer = _chatAnswer + data
      setChatAnswer(_chatAnswer)
    }).then(() => {
      setChatAnswerDone(true)
      setTimeout(() => questionInputRef.current.focus(), 100)
    })
  }

  const onQuestionFormSubmit = e => {
    e.preventDefault()
    if (!isPaidUser) {
      setShowSubscribeModal(true, "Subscribe to Ask Questions")
    }
    else {
      const formData = new FormData(e.target)
      const question = formData.get("question")

      askQuestion(question)
    }
  }

  const onInputClick = () => {
    if (!isPaidUser) {
      setShowSubscribeModal(true, "Subscribe to Ask Questions")
    }
  }

  const onInputChange = e => {
    if (!isPaidUser) {
      e.preventDefault()
      e.target.value = ""
      setShowSubscribeModal(true, "Subscribe to Ask Questions")
    }
  }

  const onShareBtnClicked = () => {
    sharePolicy(policy)
  }

  const onLikeBtnClicked = () => {
    if (isGuestUser) {
      setShowSignUpModal(true, "Sign up to Rate Policies!")
      return
    }
    likePolicy(service, user.id)

    if (isLiked == 1) {
      setIsLiked(0)
      setLikeCount(prev => prev - 1)
    } else if (isLiked == 0) {
      setIsLiked(1)
      setLikeCount(prev => prev + 1)
    } else {
      setIsLiked(1)
      setDislikeCount(prev => prev - 1)
      setLikeCount(prev => prev + 1)
    }
  }

  const onDislikeBtnClicked = () => {
    if (isGuestUser) {
      setShowSignUpModal(true, "Sign up to Rate Policies!")
      return
    }
    dislikePolicy(service, user.id)

    if (isLiked == -1) {
      setIsLiked(0)
      setDislikeCount(prev => prev - 1)
    } else if (isLiked == 0) {
      setIsLiked(-1)
      setDislikeCount(prev => prev + 1)
    } else {
      setIsLiked(-1)
      setDislikeCount(prev => prev + 1)
      setLikeCount(prev => prev - 1)
    }
  }

  const spinner = (
    <div className="spinner-grow spinner-grow-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )

  const scanCustomPolicyLinkClicked = e => {
    if (isGuestUser) {
      e.preventDefault()
      setShowSubscribeModal(true, "Scan Custom Policies")
    }
    else if (isFreeUser) {
      e.preventDefault()
      setShowSubscribeModal(true, "Subscribe to Scan")
    }
  }

  const randomScore = Math.random() * 3

  const truncated_filtered = category.policies_truncated.filter((elem) => !(elem.service === policy.service))

  const AlternativesBox = ({ className }) => {
    if (category.name == "other") return <></>
    return (
      <div className={`align-items-stretch gap-2 ${className}`}>
        <div className="d-flex flex-row flex-grow-1">
          <div className="bg-body border rounded-3 shadow-sm p-3 d-flex flex-column gap-1 w-100">
            <h4 className="fw-bold">{gradeLetterFromScore(score) == "A" ? "Alternatives" : "Best Alternatives"}</h4>
            {truncated_filtered.map((policy) => {
              return (
                <Link key={policy.service} to={"/policy/" + policy.service} className="text-decoration-none d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    {
                      policy.service_favicon
                        ?
                        <img className="rounded-circle tw-w-5 tw-h-5 me-1 d-flex justify-content-center align-items-center" src={policy.service_favicon}></img>
                        :
                        <span className="rounded-circle tw-w-5 tw-h-5 bg-primary me-1 d-flex justify-content-center align-items-center "><span className="text-white tw-text-xs">{policy.service[0].toUpperCase()}</span></span>
                    }
                    <span className="text-secondary">{policy.service}</span>
                  </div>
                  <span className={"px-2 py-auto bg-body-tertiary rounded-2 " + textColorFromScore(policy.score)} >{gradeLetterFromScore(policy.score)}</span>
                </Link>
              )
            })}
            <div className="d-flex flex-column">
              {(category.policy_count > truncated_filtered.length + 1) && <span className="text-secondary"><Link className="text-secondary" to={"/policies/" + policy.category}>{category.policy_count - truncated_filtered.length - 1} more...</Link></span>}
              <span className="text-secondary mt-1 flex-wrap">Missing a policy? <Link onClick={scanCustomPolicyLinkClicked} className="text-secondary" to="/scan-policy">Scan one here</Link></span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const HistoryBoxEntry = ({ evaluation, selected }) => {
    const textColor = selected ? "text-body" : "text-body-secondary"

    const onClick = e => {
      e.preventDefault()
      setEvaluation(evaluation)
    }

    return (
      <li className={`${textColor} d-flex flex-row justify-content-between`}>
        <Link className={`${textColor}`} onClick={onClick}>{toLocaleString(new Date(evaluation.scannedDate))}</Link>
        <span className={"px-2 py-auto bg-body-tertiary rounded-2 " + textColorFromScore(evaluation.score)} >{gradeLetterFromScore(evaluation.score)}</span>
      </li>
    )
  }

  const HistoryBox = ({ }) => {
    return (
      <div className="bg-body border rounded-3 shadow-sm p-3 pb-2 mb-2">
        <h5>History</h5>
        <ul className="list-unstyled mb-0 d-flex flex-column gap-1">
          {evaluations.map((x, i) => <HistoryBoxEntry key={x.scannedDate + i} evaluation={x} selected={x.scannedDate == evaluation.scannedDate} />)}
        </ul>
      </div>
    )
  }

  return (
    <div className="bg-body-tertiary tw-min-h-[80vh]">
      <PageTitle sub={`Information ${service} Collects About You, Summarized ${lastUpdatedString} with Best Alternatives`} />
      <div className="px-3 pt-4 pb-5 w-full">
        {user_content && (
          <div className="m-auto tw-max-w-6xl w-100 gap-3 mb-4">
            <div className="fs-6 px-2 py-1 d-flex flex-row justify-content-center text-secondary bg-secondary-subtle border-secondary rounded-2 border">
              <span><i className="bi bi-exclamation-triangle me-1"></i>This policy is not verified by a human. AI can make mistakes.</span>
            </div>
          </div>
        )}
        <div className="m-auto tw-max-w-6xl d-flex flex-row justify-content-between gap-3">
          <div className="flex-grow-1">
            <div className="d-flex flex-row align-items-center gap-2 tw-max-w-xl">
              <div className="d-flex flex-row align-items-center mb-2">
                {service_favicon && <img className="d-inline-block flex-shrink-1 me-2" width={40} src={service_favicon}></img>}
                <div className="d-flex flex-row align-items-stretch">
                  <h1 className="fw-bold display-5 mb-0"><nobr><a className="text-body" style={{ textDecoration: "none" }} href={service_url}>{service}</a></nobr></h1>
                  <div>
                    <a target="_blank" className="ms-2 fs-6" href={policy_url}><i className="bi bi-box-arrow-up-right"></i></a>
                  </div>
                </div>

              </div>
              <div className="d-flex flex-row w-100 justify-content-end gap-2">
                <div className={"bg-body-secondary d-flex justify-content-center align-items-center tw-w-8 tw-h-8 rounded-2 h3 mb-0 " + textColorFromScore(score)}>
                  {gradeLetterFromScore(score)}
                </div>
              </div>
            </div>
            <p className="fs-5 text-body-secondary">A list of information that may be collected by {service}.</p>
            <hr className="px-2 tw-max-w-xl"></hr>
            <AlternativesBox className={"d-flex d-md-none flex-column tw-max-w-xl"} />
            <div className="d-flex flex-column gap-3 mt-4">
              <form className="d-flex flex-column" onSubmit={onQuestionFormSubmit}>
                <input
                  ref={questionInputRef}
                  value={isGuestUser ? "" : undefined}
                  onClick={onInputClick}
                  onChange={onInputChange}
                  autoFocus={!!state?.chat}
                  // disabled={!isPaidUser || !chatAnswerDone}
                  placeholder="Ask a question"
                  name="question"
                  className="tw-max-w-xl rounded-pill border px-3 py-2 bg-body-secondary text-body-secondary" />
              </form>
              {chatQuestion !== null && (
                <div className="tw-max-w-xl">
                  <p className="text-body-secondary mb-0"><i className="bi bi-person me-1"></i>{chatQuestion}</p>
                  <p style={{ whiteSpace: "pre-line" }}>{chatAnswer}{!chatAnswerDone && spinner}</p>
                </div>
              )}
              {shownBehaviour && <BehaviourCard behaviour={shownBehaviour} />}
              {sortedInformationCollected.map((entry, i) => <InformationCard key={entry.type + i} id={i} information={entry} />)}
              <div className=" flex-row tw-flex md:tw-hidden w-100">
                {/* <span className="text-secondary"><small>Like this service?</small></span> */}
                <div className="d-flex gap-2 w-100 ">
                  <button onClick={onLikeBtnClicked} className={"btn btn-sm w-50 btn-light text-secondary border rounded-3 "} >{likeCount} {(isLiked === 1 ? <i className={"bi bi-hand-thumbs-up-fill "}></i> : <i className={"bi bi-hand-thumbs-up "}></i>)}</button>
                  <button onClick={onDislikeBtnClicked} className={"btn btn-sm w-50 btn-light text-secondary border rounded-3 "} >{dislikeCount} {(isLiked === -1 ? <i className={"bi bi-hand-thumbs-down-fill "}></i> : <i className={"bi bi-hand-thumbs-down "}></i>)}</button>
                </div>
              </div>
              <span className="text-secondary d-block d-md-none mt-2">
                <i className="bi bi-clock me-1"></i>{lastUpdatedString}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column align-items-end d-none d-md-block">
            <AlternativesBox className={"d-flex flex-column"} />
            <div className="py-2 tw-max-h-screen sticky-top overflow-y-hidden tw-min-w-64 d-flex flex-column" style={{ zIndex: "0" }}>
              {evaluations.length > 1 && <HistoryBox />}
              <div className="bg-body border rounded-3 shadow-sm p-3 pb-2 mb-2">
                <div className="d-flex flex-row justify-content-between align-items-stretch mb-2 gap-2">
                  <div className="d-flex flex-row align-items-center ">
                    {service_favicon && <img className="d-inline-block flex-shrink-1 me-1" width={20} src={service_favicon}></img>}
                    <h4 className="mb-0 fw-bold">{service}</h4>
                  </div>
                  <button onClick={onShareBtnClicked} className="btn btn-sm border rounded-3" ><i className="bi bi-share-fill"></i></button>
                </div>
                <ul className="list-unstyled mb-0 me-5">
                  {filteredInformationCollected.map((entry, i) => <SmallInformationLink key={entry.type + i} to={i} information={entry} />)}
                  {otherCount > 0 && (
                    <li><Link className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body" to={`#${filteredInformationCollected.length}`} replace={true}>
                      Other ({otherCount})
                    </Link></li>
                  )}
                </ul>
                <small className="text-secondary">
                  <i className="bi bi-clock me-1"></i>{lastUpdatedString}
                </small>
              </div>
              <div className="d-flex flex-row justify-content-around">
                <span className="text-secondary"><small>Like this service?</small></span>
                <div className="d-flex gap-2">
                  <button onClick={onLikeBtnClicked} className={"btn btn-sm btn-light text-secondary border rounded-3 "} >{likeCount} {(isLiked === 1 ? <i className={"bi bi-hand-thumbs-up-fill "}></i> : <i className={"bi bi-hand-thumbs-up "}></i>)}</button>
                  <button onClick={onDislikeBtnClicked} className={"btn btn-sm btn-light text-secondary border rounded-3 "} >{dislikeCount} {(isLiked === -1 ? <i className={"bi bi-hand-thumbs-down-fill "}></i> : <i className={"bi bi-hand-thumbs-down "}></i>)}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
