import { useContext, useRef, useState } from "react"
import { search } from "../Api"
import { Link, useNavigate } from "react-router-dom"
import { gradeLetterFromScore, textColorFromScore } from "../utils"
import { AuthContext } from "../providers/AuthProvider";
import { ScanPolicyContext } from "../providers/ScanPolicyProvider";

export default function SearchOrScanBar({ }) {
    const { user, isGuestUser, isFreeUser, isPaidUser } = useContext(AuthContext);

    const { scanPolicy } = useContext(ScanPolicyContext)
    const [error, setError] = useState(null)
    const inputRef = useRef(null)
    const navigate = useNavigate()

    const [policyResults, setPolicyResults] = useState([])

    const transformUrl = (url) => {
        return "https://" + url.replace("http://", "").replace("https://", "")
    }

    const PolicyResultEntry = ({ policy }) => {
        const link = `/policy/${policy.service}`
        return (
            <li className="w-100">
                <Link className="btn w-100 d-flex flex-row align-items-center justify-content-between" to={link} onClick={() => navigate(link)}>
                    <div>
                        {policy.service_favicon && <img className="d-inline-block flex-shrink-1 me-1" width={16} src={policy.service_favicon}></img>}
                        <span>{policy.service}</span>
                    </div>
                    <div className={"d-flex justify-content-center align-items-center bg-body tw-w-5 tw-h-5 rounded-2 " + textColorFromScore(policy.score)}>
                        {gradeLetterFromScore(policy.score)}
                    </div>
                </Link>
            </li>
        )
    }

    const ScanResultEntry = ({ }) => {
        return (
            <li className="w-100">
                <Link className="btn w-100 d-flex flex-row align-items-center justify-content-between" onClick={doScan}>
                    <div className="text-body-secondary">
                        <i className="bi bi-radar me-1"></i>
                        <span>Scan {transformUrl(inputRef.current.value)}</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center bg-body tw-w-5 tw-h-5 rounded-2 text-secondary">
                        ?
                    </div>
                </Link>
            </li>
        )
    }

    const doSearch = async () => {
        if (inputRef.current.value === "") {
            setPolicyResults([])
            return
        }
        if (!inputRef.current.value) return
        const res = await search(inputRef.current.value)
        const { policies } = res.search

        const sortedPolicies = policies.sort((a, b) => {
            const aStartsWithQuery = a.service.toUpperCase().startsWith(inputRef.current.value.toUpperCase());
            const bStartsWithQuery = b.service.toUpperCase().startsWith(inputRef.current.value.toUpperCase());

            if (aStartsWithQuery && bStartsWithQuery) {
                return 0;
            }
            if (aStartsWithQuery) {
                return -1;
            }
            if (bStartsWithQuery) {
                return 1;
            }
            return 0;
        });

        setPolicyResults(sortedPolicies)
        return sortedPolicies
    }

    const onInputChange = async e => {
        doSearch()
    }

    const onInputFocus = async e => {

    }

    // const onInputKeyUp = async e => {
    //     if (e.key === 'Enter') {
    //         if (policyResults.length > 0) {
    //             navigate(`/policy/${policyResults[0].service}`)
    //         }
    //     }
    // }

    const onInputBlur = async e => {
        setTimeout(() => {
            setPolicyResults([])
        }, 100)
    }

    const spinner = (
        <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )

    const onSubmit = async (e) => {
        e.preventDefault()

        if (!inputRef.current.value) {
            return
        }

        if (policyResults.length > 0) {
            navigate(`/policy/${policyResults[0].service}`)
        }
        else {
            setError(null)
            doScan()
        }
    }

    const doScan = () => {
        if (!inputRef.current.value) {
            return
        }
        const url = inputRef.current.value
        scanPolicy(transformUrl(url))
    }

    return (
        <form onSubmit={onSubmit} onFocus={onInputFocus} className="d-flex flex-column flex-lg-row gap-2 w-100">
            <div className="flex-grow-1 position-relative">
                <input
                    onChange={onInputChange} onBlur={onInputBlur}// onKeyUp={onInputKeyUp}
                    ref={inputRef}
                    placeholder={"Site or Policy URL ex: https://x.com/"}
                    className="w-100 rounded-pill border px-3 py-2 bg-body-secondary text-body-secondary" />
                {!!policyResults.length && (
                    <ul className="position-absolute top-100 w-100 bg-body-tertiary rounded-4 overflow-hidden border z-2 overflow-y-scroll list-unstyled" style={{ pointerEvents: "all", userSelect: "all" }}>
                        {!!policyResults.length && policyResults.slice(0, 10).map((entry, i) => <PolicyResultEntry key={i} policy={entry} />)}
                        <ScanResultEntry />
                    </ul>
                )}
            </div>
            <button disabled={!user} type="submit" className="btn btn-primary rounded-pill px-3 fw-bold">{false ? spinner : <span>Scan</span>}</button>
        </form>
        // <div onFocus={onInputFocus}>
        //     <div className="d-flex flex-row justify-content-center gap-2">
        //         <div className="position-relative tw-max-w-sm w-100">
        //             <input
        //                 onChange={onInputChange} onBlur={onInputBlur} onKeyUp={onInputKeyUp}
        //                 ref={inputRef}
        //                 placeholder={"Site or Policy URL ex: https://x.com/"}
        //                 className="w-100 rounded-pill border px-3 py-2 bg-body-secondary text-body-secondary" />
        //             {!!policyResults.length && (
        //                 <ul className="position-absolute top-100 w-100 bg-body-tertiary rounded-4 overflow-hidden border z-2 overflow-y-scroll list-unstyled" style={{ pointerEvents: "all", userSelect: "all" }}>
        //                     {!!policyResults.length && policyResults.map((entry, i) => <PolicyResultEntry key={i} policy={entry} />)}
        //                 </ul>
        //             )}
        //         </div>
        //         <button disabled={!user} type="submit" className="btn btn-primary rounded-pill px-3 fw-bold">{false ? spinner : <span>Scan</span>}</button>
        //     </div>
        // </div>
    )
}